<template>
    <div>
        <div v-if="authUserPermission['item-name-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <div class="card-header mt--4">
                                <div class="row">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('standard_item_name') }}</h3>
                                    </div>
                                    <div class="col text-right">
                                        <base-button size="sm" type="transparant" @click="search">
                                            <i class="fa fa-search"></i>
                                        </base-button>
                                        <base-button size="sm" type="transparant" @click="create" v-if="authUserPermission['item-name-create']">
                                            <i class="fa fa-plus-square"></i>
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad.standard_item_name" ref="singleTable"
                                    highlight-current-row
                                    @current-change="handleCurrentChange"
                                    style="width: 100%">
                                    <el-table-column :label="tt('inc')" :prop="tt('inc')" min-width="100px" sortable>
                                        <template v-slot="{row}">
                                            {{row.inc}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="437px" sortable>
                                        <template v-slot="{row}">
                                            {{row.item_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('short_name')" :prop="tt('short_name')" min-width="237px" sortable>
                                        <template v-slot="{row}">
                                            {{row.short_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="100">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['item-name-update'] || authUserPermission['item-name-delete']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['item-name-update']">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['item-name-delete']">{{ tt('delete') }}</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <skeleton-loading v-else/>
                            </div>
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card mt--4">
                                    <div class="card-body">
                                        <nav class="mb-4">
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist" style="font-size: 12px;overflow-x: auto;overflow-y: hidden;flex-wrap: nowrap;">
                                                <a :class="tabs.definition ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('definition')" role="tab" aria-controls="nav-home" :aria-selected="tabs.definition"><b>DEFINITION</b></a>
                                                <a :class="tabs.colloquial ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('colloquial')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.colloquial"><b>COLLOQUIAL</b></a>
                                                <a :class="tabs.ngc ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('ngc')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.ngc"><b>NGC</b></a>
                                                <a :class="tabs.mat_type ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mat_type')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mat_type"><b>MATERIAL TYPE</b></a>
                                                <a :class="tabs.mat_group ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('mat_group')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.mat_group"><b>MATERIAL GROUP</b></a>
                                                <!-- <a :class="tabs.service_category ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('service_category')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.service_category"><b>SERVICE CATEGORY</b></a>
                                                <a :class="tabs.service_group ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('service_group')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.service_group"><b>SERVICE GROUP</b></a> -->
                                                <a :class="tabs.image_item_name ? 'nav-item nav-link active' : 'nav-item nav-link'" data-toggle="tab" @click="tab('image_item_name')" role="tab" aria-controls="nav-contact" :aria-selected="tabs.image_item_name"><b>IMAGE</b></a>
                                            </div>
                                        </nav>

                                        <!-- TAB DEFINITION -->
                                        <div v-if="tabs.definition" style="height: 300px">
                                            <tabs tabNavClasses="flex-sm-row" v-if="!onLoad.definition">
                                                <tab-pane title="English" class="input-xs">
                                                    <div class="mt-3">
                                                        <span>{{selectedData.definition.definition_eng}}</span>
                                                    </div>
                                                </tab-pane>
                                                <tab-pane title="Indonesia">
                                                    <div class="mt-3">
                                                        <span>{{selectedData.definition.definition_ind}}</span>
                                                    </div>
                                                </tab-pane>
                                            </tabs>
                                            <skeleton-loading v-else/>
                                        </div> 

                                        <!-- TAB COLLOQUIAL -->
                                        <div v-else-if="tabs.colloquial" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.colloquial" v-if="!onLoad.colloquial">
                                                <el-table-column :label="tt('colloquial')" :prop="tt('colloquial')" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.colloquial_name}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-colloquial']" class="fa fa-trash text-danger text-right" @click="deleteColloquial(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <skeleton-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-colloquial']">
                                                <base-button size="sm" type="default" class="mt-3" @click="addColloquial" v-if="tabs.colloquial && currentRow" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div> 

                                        <!-- TAB NGC -->
                                        <div v-else-if="tabs.ngc" class="mt--4">
                                            <div class="row">
                                                <div class="col-6">
                                                    <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.ngc" v-if="!onLoad.ngc" ref="singleTable"
                                                        highlight-current-row
                                                        @current-change="handleCurrentChangeNgc"
                                                        style="width: 100%">
                                                        <el-table-column :label="tt('group_class')" :prop="tt('group_class')" min-width="100px" sortable>
                                                            <template v-slot="{row}">
                                                                {{row.nsc}}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                            <template v-slot="{row}">
                                                                {{row.description}}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column width="100">
                                                            <template v-slot="{row}">
                                                                <i v-if="authUserPermission['item-name-delete-group-class']" class="fa fa-trash text-danger text-right" @click="deleteNgc(row.id)"></i>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <skeleton-loading v-else/>
                                                    <template v-if="authUserPermission['item-name-create-group-class']">
                                                        <base-button size="sm" type="default" class="mt-3" @click="addNgc" v-if="tabs.ngc && currentRow" block>{{ tt('add_new') }}</base-button>
                                                    </template>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card m-0 shadow-sm">
                                                        <div class="card-header">
                                                            <h3 class="mb-0">{{ tt('group_definition') }}</h3>
                                                        </div>
                                                        <div class="card-body" style="height: 280px">
                                                            <div v-if="!onLoad.group_definition">
                                                                {{selectedData.group_definition}}
                                                            </div>
                                                            <skeleton-loading v-else/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- TAB MAT TYPE -->
                                        <div v-else-if="tabs.mat_type" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.mat_type" v-if="!onLoad.mat_type">
                                                <el-table-column :label="tt('material_type')" :prop="tt('material_type')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('definition')" :prop="tt('definition')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.definition}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-material-type']" class="fa fa-trash text-danger text-right" @click="deleteMatType(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <skeleton-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-material-type']">
                                                <base-button size="sm" class="mt-3" type="default" @click="addMatType" v-if="tabs.mat_type && currentRow" block>{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB MAT GROUP -->
                                        <div v-else-if="tabs.mat_group" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.mat_group" v-if="!onLoad.mat_group">
                                                <el-table-column :label="tt('material_group')" :prop="tt('material_group')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('definition')" :prop="tt('definition')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.definition}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-material-group']" class="fa fa-trash text-danger text-right" @click="deleteMatGroup(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <skeleton-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-material-group']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addMatGroup" v-if="tabs.mat_group && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB SERVICE CATEGORY -->
                                        <div v-else-if="tabs.service_category" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.service_category" v-if="!onLoad.service_category">
                                                <el-table-column :label="tt('service_category')" :prop="tt('service_category')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-service-category']" class="fa fa-trash text-danger text-right" @click="deleteServiceCategory(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <skeleton-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-service-category']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addServiceCategory" v-if="tabs.service_category && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB SERVICE GROUP -->
                                        <div v-else-if="tabs.service_group" class="mt--4">
                                            <el-table height="300px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="selectedData.service_group" v-if="!onLoad.service_group">
                                                <el-table-column :label="tt('service_group')" :prop="tt('service_group')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.code}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column :label="tt('description')" :prop="tt('description')" min-width="100px" sortable>
                                                    <template v-slot="{row}">
                                                        {{row.description}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column width="100">
                                                    <template v-slot="{row}">
                                                        <i v-if="authUserPermission['item-name-delete-service-group']" class="fa fa-trash text-danger text-right" @click="deleteServiceGroup(row.id)"></i>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <skeleton-loading v-else/>
                                            <template v-if="authUserPermission['item-name-create-service-group']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addServiceGroup" v-if="tabs.service_group && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>

                                        <!-- TAB IMAGE -->
                                        <div v-else-if="tabs.image_item_name">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div v-for="v in selectedData.image_item_name">
                                                        <el-image 
                                                            :src="v.image" 
                                                            :preview-src-list="selectedData.all_image_item_name"
                                                            style="width: 100px; height: 100px">
                                                        </el-image>
                                                        <i v-if="authUserPermission['item-name-delete-image']" class="fa fa-trash text-danger text-right" @click="deleteImageItemName(v.id)"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-if="authUserPermission['item-name-create-image']">
                                                <base-button size="sm" class="mt-3" block type="default" @click="addImageItemName" v-if="tabs.image_item_name && currentRow">{{ tt('add_new') }}</base-button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL ITEM NAME -->
            <validation-observer ref="form" v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('inc') }} <span class="text-danger">*</span></label>
                        <div v-if="form.add" class="row">
                            <div class="col-md-7">
                                <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="standard_item_name.inc" rules="required|max:5|min:5"></base-input>
                            </div>
                            <div class="col-md-5">
                                <base-button type="default" @click="generateInc" :disabled="btnGenerate.onLoading">
                                    <span v-if="btnGenerate.onLoading">
                                        <i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}
                                    </span>
                                    <span v-else>
                                        {{ tt('generate') }}
                                    </span>
                                </base-button>
                            </div>
                        </div>
                        <div v-else>
                            <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="standard_item_name.inc" disabled></base-input>
                        </div>
                        <label class="form-control-label">{{ tt('item_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('item_name')" :placeholder="tt('item_name')" v-model="standard_item_name.item_name" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('short_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('short_name')" :placeholder="tt('short_name')" v-model="standard_item_name.short_name" rules="required"></base-input>
                        <label class="form-control-label">{{ tt('english_definition') }}</label>
                        <base-input :name="tt('english_definition')" :placeholder="tt('english_definition')" v-model="standard_item_name.definition_eng"></base-input>
                        <label class="form-control-label">{{ tt('indonesia_definition') }}</label>
                        <base-input :name="tt('indonesia_definition')" :placeholder="tt('indonesia_definition')" v-model="standard_item_name.definition_ind"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- SEARCH ITEM NAME -->
            <modal :show.sync="formSearch.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('search_item_name') }}</h5>
                </template>
                <div>
                    <base-input :name="tt('item_name')" :placeholder="tt('inc_or_item_name')" v-model="searchStandardItemName.item_name" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('colloquial')" :placeholder="tt('colloquial')" v-model="searchStandardItemName.colloquial" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('definition')" :placeholder="tt('definition')" v-model="searchStandardItemName.definition" v-on:keyup.enter="filter"></base-input>
                    <base-input :name="tt('group_class')" :placeholder="tt('group_class')" v-model="searchStandardItemName.group_class" v-on:keyup.enter="filter"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formSearch.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="filter" :disabled="btnSearch.onLoading">
                        <span v-if="btnSearch.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{tt('search')}}
                        </span>
                    </base-button>
                </template>
            </modal>

            <!-- ADD COLLOQUAIL -->
            <validation-observer ref="formColloquial" v-slot="{invalid}">
                <modal :show.sync="formColloquial.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_colloquial') }}</h5>
                    </template>
                    <div>
                        <base-input :name="tt('colloquial')" :placeholder="tt('colloquial')" v-model="colloquial.colloquial_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formColloquial.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveColloquial" :disabled="btnAddColloquial.onLoading || invalid">
                            <span v-if="btnAddColloquial.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD NGC -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formNgc.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_group_class') }}</h5>
                    </template>
                    <div class="row">
                        <el-select class="form-control-sm col-md-12 mb-4 " v-model="ngc.nsc" :placeholder="tt('choose_group_class')" :remote-method="getNatoGroupClass" :loading="loading" filterable remote reserve-keyword clearable>
                            <el-option v-for="ngc in natoGroupClass" :key="ngc.nsc" :label="ngc.nsc +' - '+ ngc.description" :value="ngc.nsc"></el-option>
                        </el-select>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formNgc.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveNgc" :disabled="btnAddNgc.onLoading || invalid">
                            <span v-if="btnAddNgc.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD MATERIAL TYPE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMatType.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_material_type') }}</h5>
                    </template>
                    <div class="form-group  ">                        
                        <el-select filterable class="select-danger" v-model="mat_type.material_type_code" :placeholder="tt('choose_material_type')">
                            <el-option class="select-danger" :value="mt['code']" :label="mt['code'] + ' - ' + mt['value']" :key="mt['code'] + ' - ' + mt['value']" v-for="mt in materialType">{{ mt['code'] }} - {{ mt['value'] }}</el-option>
                        </el-select>                        
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMatType.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMatType" :disabled="btnAddMatType.onLoading || invalid">
                            <span v-if="btnAddMatType.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD MATERIAL GROUP -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formMatGroup.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_material_group') }}</h5>
                    </template>
                    <div class="row">
                        <el-select class="form-control-sm col-md-12 mb-4 " v-model="mat_group.material_group_code" :placeholder="tt('choose_material_group')" :remote-method="getMaterialGroup" :loading="loading" filterable remote reserve-keyword clearable>
                            <el-option v-for="mg in materialGroup" :key="mg.code" :label="mg.code +' - '+ mg.description" :value="mg.code"></el-option>
                        </el-select>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formMatGroup.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveMatGroup" :disabled="btnAddMatGroup.onLoading || invalid">
                            <span v-if="btnAddMatGroup.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD SERVICE CATEGOORY -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formServiceCategory.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_service_category') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="service_category.service_category_code"
                            :fetch-suggestions="querySearchServiceCategory"
                            :placeholder="tt('choose_service_category')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('service_category')" :placeholder="tt('service_category')" v-model="service_category.service_category_code" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formServiceCategory.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveServiceCategory" :disabled="btnAddServiceCategory.onLoading || invalid">
                            <span v-if="btnAddServiceCategory.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD SERVICE GROUP -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formServiceGroup.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_service_group') }}</h5>
                    </template>
                    <div class="row">
                        <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="service_group.service_group_code"
                            :fetch-suggestions="querySearchServiceGroup"
                            :placeholder="tt('choose_service_group')"
                            @select="handleSelect"
                            size="large"
                        ></el-autocomplete>
                        <!-- <base-input :name="tt('service_group')" :placeholder="tt('service_group')" v-model="service_group.service_group_code" rules="required"></base-input> -->
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formServiceGroup.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveServiceGroup" :disabled="btnAddServiceGroup.onLoading || invalid">
                            <span v-if="btnAddServiceGroup.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- ADD IMAGE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formImageItemName.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('add_new_image_item_name') }}</h5>
                    </template>
                    <div>
                        <file-input @change="filesChange" ref="file"></file-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formImageItemName.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveImageItemName" :disabled="btnAddImageItemName.onLoading || invalid">
                            <span v-if="btnAddImageItemName.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{tt('add')}}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';
    import materialType from '@/services/master/materialType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import serviceCategory from '@/services/master/serviceCategory.service';
    import serviceGroup from '@/services/master/serviceGroup.service';
    import router from '@/routes/router';
    import config from '@/configs/config';

    export default {        
        data() {
            return {       
                onLoad: {
                    standard_item_name: true,
                    definition: false,
                    colloquial: false,
                    ngc: false,
                    mat_type: false,
                    mat_group: false,
                    service_category: false,
                    service_group: false,
                    image_item_name: false,
                    group_definition: false,
                },
                loading: false,
                loadTimeout: null,     
                btnSearch: {
                    onLoading: false
                },        
                btnSave: {
                    onLoading: false
                },
                btnAddColloquial: {
                    onLoading: false
                },
                btnAddNgc: {
                    onLoading: false
                },
                btnAddMatType: {
                    onLoading: false
                },
                btnAddMatGroup: {
                    onLoading: false
                },
                btnAddServiceCategory: {
                    onLoading: false
                },
                btnAddServiceGroup: {
                    onLoading: false
                },
                btnAddImageItemName: {
                    onLoading: false
                },
                btnGenerate: {
                    onLoading: false
                },
                form: {
                    add: true,
                    title: this.tt('add_new_mcr'),
                    show: false,
                },
                formSearch: {
                    show: false
                }, 
                formImage: {
                    show: false
                }, 
                formColloquial: {
                    show: false
                }, 
                formNgc: {
                    show: false
                }, 
                formMatType: {
                    show: false
                }, 
                formMatGroup: {
                    show: false
                }, 
                formServiceCategory: {
                    show: false
                }, 
                formServiceGroup: {
                    show: false
                }, 
                formImageItemName: {
                    show: false
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: [],
                },     
                errorMessage: null,          
                searchStandardItemName: {
                    'item_name': '',
                    'colloquial': '',
                    'definition': '',
                    'group_class': '',
                },
                standardItemName: {},
                standard_item_name: {
                    inc: '',
                    definition_eng: '',
                    definition_ind: '',
                },
                currentRow: null,
                tabs: {
                    definition: false,
                    colloquial: false,
                    ngc: false,
                    mat_type: false,
                    mat_group: false,
                    service_category: false,
                    service_group: false,
                    image_item_name: false,
                },
                selectedData: {
                    definition: {},
                    colloquial: [],
                    ngc: [],
                    mat_type: [],
                    mat_group: [],
                    service_category: [],
                    service_group: [],
                    image_item_name: [],
                    all_image_item_name: [],
                    group_definition: ''
                },
                colloquial: {},
                ngc: {},
                mat_type: {},
                mat_group: {},
                service_category: {},
                service_group: {},
                image_item_name: {},
                links_mat_type: [],
                links_service_category: [],
                links_service_group: [],
                natoGroupClass: [],
                materialType: [],
                materialGroup: [],
                serviceCategory: [],
                serviceGroup: [],
            }
        },
        computed: {
            ...mapState({
                authUserPermission: state => state.auth.userPermission
            })
        },
        watch: {
            '$route': function() {
                this.get();
            },
        },
        mounted() {
            this.tab('definition')
            this.get()
            this.getMaterialType()
            // this.getServiceCategory()
            // this.getServiceGroup()
            this.links_mat_type = this.materialType;
            this.links_service_category = this.serviceCategory;
            this.links_service_group = this.serviceGroup;
        },
        methods: {
            addColloquial() {
                this.formColloquial.show = true;
                this.$refs.formColloquial.reset();
                this.colloquial = {};   
            },
            saveColloquial() {
                let context = this;
                context.btnAddColloquial.onLoading = true;
                context.colloquial.inc = context.currentRow.inc;
                context.onLoad.colloquial = true;
                Api(context, standardItemName.add_colloquial(this.colloquial)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formColloquial.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.colloquial = {};
                }).onError(function(error) {
                    context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.btnAddColloquial.onLoading = false;
                    context.onLoad.colloquial = false;
                }).call()
            },
            deleteColloquial(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.colloquial = true;
                        Api(context, standardItemName.delete_colloquial(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.handleCurrentChange(context.currentRow);
                        }).call();
                    }
                })
            },
            getNatoGroupClass(query) {
                if (query.length > 2) {
                    clearTimeout(this.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.loadTimeout = setTimeout(() => {
                        Api(context, natoGroupClass.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.natoGroupClass = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.natoGroupClass = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            addNgc() {
                this.formNgc.show = true;
            },
            saveNgc() {
                let context = this;
                context.btnAddNgc.onLoading = true;
                context.ngc.inc = context.currentRow.inc;
                context.onLoad.ngc = true;
                Api(context, standardItemName.add_group_class(this.ngc)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formNgc.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.ngc = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Group Class isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddNgc.onLoading = false;
                    context.onLoad.ngc = false;
                }).call()
            },
            deleteNgc(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.ngc = true;
                        Api(context, standardItemName.delete_group_class(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.handleCurrentChange(context.currentRow);
                        }).call();
                    }
                })
            },
            getMaterialType() {
                let context = this;               
                Api(context, materialType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.materialType[index] = {
                            'code': item['code'],
                            'value': item['description']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            addMatType() {
                this.formMatType.show = true;
            },
            saveMatType() {
                let context = this;
                context.btnAddMatType.onLoading = true;
                context.mat_type.inc = context.currentRow.inc;
                context.onLoad.mat_type = true;
                Api(context, standardItemName.add_material_type(this.mat_type)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formMatType.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.mat_type = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Material type isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddMatType.onLoading = false;
                    context.onLoad.mat_type = false;
                }).call()
            },
            deleteMatType(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.mat_type = true;
                        Api(context, standardItemName.delete_material_type(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.mat_type = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getMaterialGroup(query) {
                if (query.length > 1) {
                    clearTimeout(this.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.loadTimeout = setTimeout(() => {
                        Api(context, materialGroup.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                            context.materialGroup = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.materialGroup = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            addMatGroup() {
                this.formMatGroup.show = true;
            },
            saveMatGroup() {
                let context = this;
                context.btnAddMatGroup.onLoading = true;
                context.mat_group.inc = context.currentRow.inc;
                context.onLoad.mat_group = true;
                Api(context, standardItemName.add_material_group(this.mat_group)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formMatGroup.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.mat_group = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Material group isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddMatGroup.onLoading = false;
                    context.onLoad.mat_group = false;
                }).call()
            },
            deleteMatGroup(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.mat_group = true;
                        Api(context, standardItemName.delete_material_group(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.mat_group = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getServiceCategory() {
                let context = this;               
                Api(context, serviceCategory.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.serviceCategory[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.serviceCategory = [];
                    }
                })
                .call()
            },
            addServiceCategory() {
                this.formServiceCategory.show = true;
            },
            saveServiceCategory() {
                let context = this;
                context.btnAddServiceCategory.onLoading = true;
                context.service_category.inc = context.currentRow.inc;
                context.onLoad.service_category = true;
                Api(context, standardItemName.add_service_category(this.service_category)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formServiceCategory.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.service_category = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Service category isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddServiceCategory.onLoading = false;
                    context.onLoad.service_category = false;
                }).call()
            },
            deleteServiceCategory(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.service_category = true;
                        Api(context, standardItemName.delete_service_category(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.service_category = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            getServiceGroup() {
                let context = this;               
                Api(context, serviceGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.serviceGroup[index] = {
                            'code': item['code'],
                            'value': item['code']
                        };
                    });                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.serviceGroup = [];
                    }
                })
                .call()
            },
            addServiceGroup() {
                this.formServiceGroup.show = true;
            },
            saveServiceGroup() {
                let context = this;
                context.btnAddServiceGroup.onLoading = true;
                context.service_group.inc = context.currentRow.inc;
                context.onLoad.service_group = true;
                Api(context, standardItemName.add_service_group(this.service_group)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formServiceGroup.show = false;
                    context.handleCurrentChange(context.currentRow);
                    context.service_group = {};
                }).onError(function(error) {
                    if (error.response.status == 500) {
                        context.$notify({
                            message: 'Service group isn\'t exist',                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    } else {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }
                }).onFinish(function() {
                    context.btnAddServiceGroup.onLoading = false;
                    context.onLoad.service_group = false;
                }).call()
            },
            deleteServiceGroup(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.service_group = true;
                        Api(context, standardItemName.delete_service_group(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.service_group = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).call();
                    }
                })
            },
            addImageItemName() {
                this.formImageItemName.show = true;
            },
            filesChange(files) {
                this.image_item_name.image = files[0];
            },
            saveImageItemName() {
                let context = this;
                let formData = new FormData();
                context.btnAddImageItemName.onLoading = true;
                context.onLoad.image_item_name = true;

                if (context.image_item_name.image.type == 'image/png' || context.image_item_name.image.type == 'image/jpeg') {
                    formData.append('inc', context.currentRow.inc);
                    formData.append('image[]', context.image_item_name.image);
                    
                    Api(context, standardItemName.add_image_item_name(formData)).onSuccess(function(response) {
                        context.btnAddImageItemName.onLoading = false;
                        context.formImageItemName.show = false;
                        context.handleCurrentChange(context.currentRow);
                        context.image_item_name = {};
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).call()
                } else {
                    context.$notify({
                        message: 'Format image must jpeg/png.',                  
                        type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                    }); 
                    context.errorMessage = {
                        status: "Fail",
                        message: "Standard Item Name Validation Error",
                        data: {
                            abbreviation: [
                                "Format image must jpeg/png."
                            ]
                        }
                    };
                    
                    context.onLoad.image_item_name = false;
                    context.btnAddImageItemName.onLoading = false;
                }

            },
            deleteImageItemName(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.onLoad.image_item_name = true;
                        Api(context, standardItemName.delete_image_item_name(id)).onSuccess(function(response) {
                            if (response.data.data == 1) {
                                context.onLoad.image_item_name = false;
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });
                                context.handleCurrentChange(context.currentRow);
                            } else {
                                context.errorMessage = error.response.data;
                            }
                        }).onFinish(function() {
                            context.onLoad.image_item_name = false;
                        }).call();
                    }
                })


                // this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                //     let context = this;
                //     if (result.value) {
                //         context.onLoad.service_group = true;
                //         Api(context, standardItemName.delete_service_group(id)).onSuccess(function(response) {
                //             if (response.data.data == 1) {
                //                 context.onLoad.service_group = false;
                //                 context.$notify({
                //                     message: response.data.message,
                //                     type: 'success',
                //                     verticalAlign: 'bottom', 
                //                     horizontalAlign: 'left'
                //                 });
                //                 context.handleCurrentChange(context.currentRow);
                //             } else {
                //                 context.errorMessage = error.response.data;
                //             }
                //         }).call();
                //     }
                // })
            },
            generateInc() {
                let context = this;
                context.btnGenerate.onLoading = true;
                Api(context, standardItemName.generate_inc()).onSuccess(function(response) {                                       
                    context.standard_item_name.inc = response.data.data;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnGenerate.onLoading = false;
                }).call();
            },
            tab(type) {
                if (type == 'definition') {
                    this.tabs.definition = true;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'colloquial') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = true;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'ngc') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = true;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'mat_type') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = true;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'mat_group') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = true;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'service_category') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = true;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = false;
                } else if (type == 'service_group') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = true;
                    this.tabs.image_item_name = false;
                } else if (type == 'image_item_name') {
                    this.tabs.definition = false;
                    this.tabs.colloquial = false;
                    this.tabs.ngc = false;
                    this.tabs.mat_type = false;
                    this.tabs.mat_group = false;
                    this.tabs.service_category = false;
                    this.tabs.service_group = false;
                    this.tabs.image_item_name = true;
                }
            },
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                let context = this;
                context.currentRow = val;

                if (context.tabs.definition) {
                    context.onLoad.definition = true;
                } else if (context.tabs.colloquial) {
                    context.onLoad.colloquial = true;
                } else if (context.tabs.ngc) {
                    context.onLoad.ngc = true;
                } else if (context.tabs.mat_type) {
                    context.onLoad.mat_type = true;
                } else if (context.tabs.mat_group) {
                    context.onLoad.mat_group = true;
                } else if (context.tabs.service_category) {
                    context.onLoad.service_category = true;
                } else if (context.tabs.service_group) {
                    context.onLoad.service_group = true;
                } else if (context.tabs.image_item_name) {
                    context.onLoad.image_item_name = true;
                }
                Api(context, standardItemName.data(val.inc)).onSuccess(function(response) {
                    context.selectedData.definition = response.data.data.data_definition[0];
                    context.selectedData.colloquial = response.data.data.data_colloquial;
                    context.selectedData.ngc = response.data.data.data_group_class;
                    context.selectedData.mat_type = response.data.data.data_material_type;
                    context.selectedData.mat_group = response.data.data.data_material_group;
                    context.selectedData.service_category = response.data.data.data_service_category;
                    context.selectedData.service_group = response.data.data.data_service_group;
                    context.selectedData.image_item_name = {};
                    response.data.data.data_image.forEach(function(item, index){
                        context.selectedData.image_item_name[index] = {
                            'id': item['id'],
                            'image': config.storageUrl+'images/image_item_name/'+item['image']
                        };
                    })
                    context.selectedData.all_image_item_name = [];
                    response.data.data.data_image.forEach(function(item){
                        context.selectedData.all_image_item_name.push(config.storageUrl+'images/image_item_name/'+item['image'])
                    })
                    context.selectedData.group_definition = '';
                })
                .onError(function (error) {
      
                })
                .onFinish(function() {
                    if (context.onLoad.definition) {
                        context.onLoad.definition = false;
                    } else if (context.onLoad.colloquial) {
                        context.onLoad.colloquial = false;
                    } else if (context.onLoad.ngc) {
                        context.onLoad.ngc = false;
                    } else if (context.onLoad.mat_type) {
                        context.onLoad.mat_type = false;
                    } else if (context.onLoad.mat_group) {
                        context.onLoad.mat_group = false;
                    } else if (context.onLoad.service_category) {
                        context.onLoad.service_category = false;
                    } else if (context.onLoad.service_group) {
                        context.onLoad.service_group = false;
                    } else if (context.onLoad.image_item_name) {
                        context.onLoad.image_item_name = false;
                    }
                }).call()
            },
            handleCurrentChangeNgc(val) {
                let context = this;
                context.onLoad.group_definition = true;
                Api(context, standardItemName.group_class_def(val.nsc)).onSuccess(function(response) {
                    if (context.tabs.ngc) {
                        context.selectedData.group_definition = response.data.data[0].definition_eng;
                    } else {
                        context.selectedData.group_definition = '-';
                    }
                }).onFinish(function() {
                    context.onLoad.group_definition = false;
                }).call()
            },
            get() {
                let context = this;

                Api(context, standardItemName.get(this.table.page.current_page, this.$route.query)).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
                    context.onLoad.standard_item_name = false;
                    context.onLoad.definition = false;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad.standard_item_name = false;
                        context.onLoad.definition = false;
                    }
                }).call()
            },
            filter() {
                this.$router.push({ path: 'standard-item-name', query: this.searchStandardItemName });

                this.btnSearch.onLoading = true;
                this.onLoad.standard_item_name = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get();
                    this.searchStandardItemName = {
                        'item_name': '',
                        'colloquial': '',
                        'definition': '',
                        'group_class': '',
                    }  
                    this.btnSearch.onLoading = false;
                    this.formSearch.show = false;
                    this.onLoad.standard_item_name = false;
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_item_name');
                this.form.show = true;
                this.standard_item_name = {};
                this.standardItemName = {
                    'definition_eng': '',
                    'definition_ind': '',
                };
                this.$refs.form.reset();
            },            
            edit(id) {
                let context = this;      
                context.errorMessage = null;

                Api(context, standardItemName.show(id)).onSuccess(function(response) {
                    context.standard_item_name = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('update_item_name');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                let context = this;
                let api = null;
                context.btnSave.onLoading = true;

                if (context.form.add) {
                    api = Api(context, standardItemName.create(context.standard_item_name));
                } else {{
                    api = Api(context, standardItemName.update(context.standard_item_name.id, context.standard_item_name));
                }}

                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });                    
                    context.form.show = false;
                }).onError(function(error) {           
                    context.btnSave.onLoading = false;         
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad.standard_item_name = true;
                        Api(context, standardItemName.delete(id)).onSuccess(function(response) {
                            context.get();
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).onFinish(function() {
                            context.onLoad.standard_item_name = false;
                        }).call();
                    }
                })
            },
            search() {
                this.formSearch.show = true
            },
            image() {
                this.formImage.show = true
            },
            closeImage() {
                this.formImage.show = false
            },
            send(id) {
                this.confirmDialog(this.tt('confirm_send')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_send'));
                    } else {
                        this.alertDialog('error', this.tt('error_send'));
                    }
                })
            },

            querySearchMatType(queryString, cb) {
                var links = this.links_mat_type;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchServiceCategory(queryString, cb) {
                var links = this.links_service_category;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            querySearchServiceGroup(queryString, cb) {
                var links = this.links_service_group;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            createFilter(queryString) {
                return (link) => {
                  return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadAll(materialType) {
                return materialType;
            },
            handleSelect() {
                
            },
            changePage(page) {
                let context = this;
                context.onLoad.standard_item_name = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            }
        }   
    };
</script>
<style></style>
